/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();



.error-snackbar {
    --mdc-snackbar-container-color: theme('colors.warn-200');
    --mat-mdc-snack-bar-button-color: var(--primary-color);
    --mdc-snackbar-supporting-text-color: var(--primary-color);
}

.ql-mention-list {
    background-color: white;
    list-style-type: none;
    transform: translateY(-2px);
    margin-top: 0;
    padding: 0px 0px 0px 0px;
    z-index: 100;
}

.ql-mention-list .ql-mention-list-item {
    cursor: pointer;
    padding: 10px 10px;
}

.ql-mention-list .ql-mention-list-item.selected {
    background-color: rgba(0, 0, 0, 0.12);
}

span.mention {
    background-color: rgba(0, 0, 0, 0.12);
    padding: 2px 5px;
    border-radius: 2px;
}
